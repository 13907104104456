var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-userAccount"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('MyAccount')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('UserProfile', {
    attrs: {
      "user-u-u-i-d": _vm.$appConfig.user._id
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }